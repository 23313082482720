'use client'
import { ReactNode, SetStateAction, createContext, useMemo, useState } from 'react'
import { SearchType } from './types'
type SearchContextProps = {
    searchType?: SearchType
    setSearchType: React.Dispatch<SetStateAction<SearchType | undefined>>
}

export const SearchTypeContext = createContext({
    searchType: 'gifs',
} as SearchContextProps)

const SearchTypeContextManager = ({ children }: { children: ReactNode }) => {
    const [searchType, setSearchType] = useState<SearchType>()
    const value = useMemo(() => ({ searchType, setSearchType }), [searchType, setSearchType])
    return <SearchTypeContext.Provider value={value}>{children}</SearchTypeContext.Provider>
}

export default SearchTypeContextManager
